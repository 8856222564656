<template>
  <div ref="slider" class="wrapper-slider">
    <div class="slider">
      <div v-for="(item, index) in data" :key="index">
        <transition name="slide-animation" mode="out-in" :appear="true">
          <router-link
            :to="
              $i18nRoute({
                name: 'AnnouncementDetail',
                params: { slug: item.id },
              })
            "
            v-show="index === activeIndex"
            class="slide"
          >
            <div class="scale-content" :class="{ loaded }">
              <div class="slide-title limited-text" style="--snup: 3">
                {{ item?.title }}
              </div>
              <div class="slide-description limited-text">
                {{ item?.brief }}
              </div>
              <PrimaryLink
                class="slide-link"
                routeName="AnnouncementDetail"
                :routeParams="{ slug: item.id }"
                :title="$t('buttons.more')"
              />
            </div>
          </router-link>
        </transition>
      </div>
    </div>
    <div class="controls">
      <button @click="incrementActiveIndex(-1)" class="left">
        <svg
          width="30"
          height="22"
          viewBox="0 0 30 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.0957 12.5228C28.8826 12.5228 29.5205 11.8848 29.5205 11.0979C29.5205 10.311 28.8826 9.6731 28.0957 9.6731L28.0957 12.5228ZM0.491425 10.0904C-0.0650043 10.6468 -0.0650044 11.549 0.491424 12.1054L9.55897 21.173C10.1154 21.7294 11.0175 21.7294 11.574 21.173C12.1304 20.6165 12.1304 19.7144 11.574 19.158L3.51394 11.0979L11.574 3.03788C12.1304 2.48145 12.1304 1.5793 11.574 1.02287C11.0176 0.466442 10.1154 0.466442 9.55897 1.02287L0.491425 10.0904ZM28.0957 9.6731L1.49893 9.67309L1.49893 12.5227L28.0957 12.5228L28.0957 9.6731Z"
            fill="#7D7D7D"
          />
        </svg>
      </button>
      <button @click="incrementActiveIndex(1)" class="right">
        <svg
          width="30"
          height="22"
          viewBox="0 0 30 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.57129 9.6731C0.784379 9.6731 0.146462 10.311 0.146462 11.0979C0.146462 11.8848 0.784379 12.5228 1.57129 12.5228L1.57129 9.6731ZM29.1756 12.1054C29.732 11.549 29.732 10.6469 29.1756 10.0904L20.108 1.02288C19.5516 0.466447 18.6494 0.466446 18.093 1.02288C17.5366 1.57931 17.5366 2.48146 18.093 3.03789L26.1531 11.0979L18.093 19.158C17.5366 19.7144 17.5366 20.6165 18.093 21.173C18.6494 21.7294 19.5516 21.7294 20.108 21.173L29.1756 12.1054ZM1.57129 12.5228L28.1681 12.5228L28.1681 9.6731L1.57129 9.6731L1.57129 12.5228Z"
            fill="#7D7D7D"
          />
        </svg>
      </button>
      <div class="wrapper-pagination">
        <div
          class="wrapper-pagination__bullet"
          v-for="(_, index) in data"
          :key="index"
        >
          <div v-show="index == activeIndex" class="active-bullet"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryLink from "@/components/PrimaryLink.vue";
import { useStore } from "vuex";
import { onBeforeUnmount, onMounted, ref, computed } from "vue";
3;
export default {
  components: { PrimaryLink },
  props: {
    loaded: {
      default: true,
      require: false,
    },
  },
  setup() {
    const activeIndex = ref(0);
    const store = useStore();
    const limit = 5;
    const data = computed(() => store.getters["articles/getArticlesList"]);

    store.dispatch("articles/getLimiteArticlesList", {
      cb: () => {},
      limit: limit,
    });

    let mainInterval;

    const incrementActiveIndex = (to = 1) => {
      clearInterval(mainInterval);
      activeIndex.value =
        activeIndex.value + to < 0
          ? (activeIndex.value = data.value.length - 1)
          : activeIndex.value + to > data.value.length - 1
          ? 0
          : activeIndex.value + to;
      mainInterval = setInterval(incrementActiveIndex, 5000);
    };
    onMounted(() => {
      // var slide = document.querySelectorAll("slide");
      mainInterval = setInterval(incrementActiveIndex, 5000);
    });
    onBeforeUnmount(() => {
      if (mainInterval) clearInterval(mainInterval);
    });

    return {
      data,
      activeIndex,
      incrementActiveIndex,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper-slider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .controls {
    display: flex;
    align-items: center;
    .left,
    .right {
      border-radius: 1.2em;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3em;
      height: 2.1em;
      cursor: pointer;

      svg {
        path {
          fill: var(--primary-blue);
        }
      }
    }
    .left {
      margin-right: 1rem;
    }
    .right {
      margin-left: 0.2em;
      margin-right: 1rem;
    }
    .wrapper-pagination {
      display: flex;
      &__bullet {
        position: relative;
        width: 0.75rem;
        height: 0.75rem;
        background: #e9ecf0;
        border-radius: 0.1em;
        margin-left: 0.2em;
        margin-right: 0.2em;
        border-radius: 50%;
        .active-bullet {
          position: absolute;
          top: 0;
          left: 0;
          width: 0.75rem;
          height: 0.75rem;
          background: var(--primary-blue);
          border-radius: 50%;
        }
      }
    }
  }
}
.slider {
  position: relative;
  height: 60%;
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;

    &-link {
      width: max-content;
      margin-bottom: 2.5rem;
    }

    .scale-content {
      transform-origin: 0 0;
      transform: scale(0);
      transition: all 0.2s ease-in;
      &.loaded {
        transition-delay: 0.4s;
        transform: scale(1);
      }
    }
    &-img {
      padding-bottom: 34%;
      width: max(33.0625rem, 60%);
      :deep() {
        img {
          object-fit: fill;
        }
      }
    }
    &-title {
      font-weight: 600;
      font-size: 3em;
      margin-top: 1rem;
      color: var(--dark-blue);
      width: 85%;
    }
    &-description {
      margin-top: 1.075rem;
      margin-bottom: 2.5rem;
      font-weight: 400;
      font-size: 1.25em;
      color: #5c6077;
      --snup: 5;
    }
  }
}
// @media screen and (max-width: 1200px) {
//   .slider {
//     .slide {
//       &-img {
//         width: 100%;
//         padding-bottom: 52%;
//       }
//       &-title {
//         width: 100%;
//       }
//     }
//   }
// }
@media screen and (max-width: 1280px) {
  .wrapper-slider {
    margin-top: 2.5em;
    justify-content: flex-start;

    .controls {
      position: absolute;
      bottom: 6.5rem;
    }

    .slider {
      width: 90%;

      .slide {
        &-btn {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
        }
        &-title {
          margin-top: 1em;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper-slider {
    margin-top: 0;
    .controls {
      bottom: 2rem;
    }
    .slider {
      .slide {
        &-title {
          font-size: 1.75em;
        }

        &-desctiption {
          font-size: 0.875em;
        }

        &-link {
          margin-top: 1.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper-slider {
    margin-top: 1rem;
    display: flex;

    .controls {
      top: 12rem;
      height: 6.25rem;
      .left,
      .right {
        width: 2.8494rem;
        height: 2.1375rem;

        border-radius: 1.3655rem;
      }
    }
    .slider {
      width: 100%;
      height: 24.6875rem;
      .slide {
        &-img {
          max-width: auto;
          width: 100%;
          // padding-bottom: 39%;
        }
        &-title {
          margin-top: 0.5em;
          width: 100%;
          font-size: 1.25em;
          --snup: 4;
        }
        &-description {
          margin-bottom: 0;
          font-size: 0.875em;
          --snup: 3;
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .wrapper-slider {
    .slider {
      width: 100%;
    }
  }
}

.slide-animation-enter-active {
  transition: all 0.5s ease-in;

  transition-delay: 0.1s;
}
.slide-animation-leave-active {
  transition: all 0.5s ease-out;
}

.slide-animation-enter-from {
  opacity: 0;
  // transform: translateX(100%);
}
.slide-animation-leave-to {
  // opacity: 0.5;

  opacity: 1;
  transform: translateX(-100%);
}
</style>
