<template>
  <div class="home">
    <div class="container">
      <div class="home-wrapper">
        <div class="bg-image">
          <img src="@/assets/img/main_bg.jpg" alt="" />
        </div>
        <div class="left" :class="{ loaded }">
          <HeroSlider :loaded="loaded" />
        </div>
      </div>
    </div>
    <RecentEvents />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import HeroSlider from "@/components/HeroSlider.vue";
import RecentEvents from "@/components/RecentEvents.vue";
export default {
  name: "Home",
  components: {
    HeroSlider,
    RecentEvents,
  },
  setup() {
    const loaded = ref(false);
    onMounted(() => {
      setTimeout(() => (loaded.value = true), 100);
    });
    return { loaded };
  },
};
</script>

<style lang="scss" scoped>
.bg-image {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;

  img {
    width: 56.75rem;
    height: 41.25rem;
  }
}

.home-wrapper {
  --transition-time: 0.6s;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 41.25rem auto;
  position: relative;

  .left {
    transition: all var(--transition-time) ease;
    transform: translateX(-100%);
    max-width: 56.25rem;
    width: 100%;
    &.loaded {
      transform: translateX(0);
    }
  }
  .right {
    transform: translateX(100%);
    transition: transform var(--transition-time);
    &.loaded {
      transform: translateX(0);
    }
    .Calendar {
      width: 80%;
      margin: 3rem auto 0;
    }
  }
  .bottom-logo {
    margin-left: auto;
    grid-column: 2 / span 1;
    display: flex;
    width: 28%;
    min-width: 40rem;
    gap: 1.8rem;
    margin-top: 2rem;
    transition: all var(--transition-time);
    transform: translateX(100%) scale(0);
    align-items: center;
    &.loaded {
      transform: translateX(0) scale(1);
    }
    .img {
      flex: 0 0 80px;
      background: transparent;
      object-fit: contain;
      // padding-bottom: 2%;
    }
    .descr {
      font-weight: 700;
      font-size: 1.2em;
      line-height: 1.2;
    }
  }
}
@media screen and (max-width: 1280px) {
  .bg-image {
    right: -0.94rem;
    img {
      width: 43.75rem;
      height: 100%;
    }
  }

  .home-wrapper {
    grid-template-rows: 37.5rem auto;

    .right {
      .Calendar {
        margin-top: 2rem;
      }
    }
  }
}
// @media screen and (max-width: 1200px) {
//   .home-wrapper {
//     .bottom-logo {
//       min-width: 100%;
//     }
//     .left {
//       width: 85%;
//     }
//     .right {
//       .Calendar {
//         width: 100%;
//         margin-left: auto;
//       }
//     }
//   }
// }
@media screen and (max-width: 768px) {
  .bg-image {
    img {
      width: 31rem;
      height: 100%;
    }
  }
  .home-wrapper {
    display: grid;
    // grid-template-columns: 1fr;height: 335px;
    grid-template-rows: 24rem auto;

    .left {
      margin-top: 0;
      grid-column: span 2;
      width: 100%;
    }
    .right {
      grid-column: span 2;
      .Calendar {
        width: 80%;
        max-width: 720px;
        margin: 8rem auto 2rem;
      }
    }
    .bottom-logo {
      min-width: 100%;
      margin: 5rem 0 2rem;
      grid-column: span 2;
    }
  }
}
@media screen and (max-width: 575px) {
  .bg-image {
    width: 100vw;
    height: max-content;
    bottom: -0.2rem;
    right: -1rem;
    top: auto;

    img {
      width: 100%;
      height: 18rem;
    }
  }
  .home-wrapper {
    display: block;
    .left {
      height: 36rem;
    }
    .right {
      height: 100%;
    }
  }
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-wrapper {
    grid-template-rows: 65vw auto;
  }
}
</style>
