<template>
  <div class="events-mobile">
    <div class="item" v-for="(event, index) in events" :key="index">
      <h3 class="item-title" style="text-transform: lowercase">
        {{ $t("events.examNumKo") }}{{ event?.exam_numb
        }}{{ $t("events.examNum") }} {{ $t("events.exam") }}
      </h3>
      <div class="item-table">
        <div class="row">
          <span>{{ $t("events.exam") }}</span>
          <template v-for="examType in examTypes" :key="examType?.id">
            <span v-if="examType?.id == event?.exam_type" class="flex">
              <div
                class="indicator"
                :style="{ background: examType?.color }"
              ></div>
              {{ examType?.title }}
            </span>
          </template>
        </div>
        <div class="row">
          <span>{{ $t("events.examDate") }}</span>
          <div class="flex-column">
            <span> {{ event?.exam_numb }}{{ $t("events.examNum") }} </span>
            <span>
              {{ handleDate(event?.exam_date) }}({{
                $t("week." + getWeek(event?.exam_date))
              }})
            </span>
          </div>
        
        </div>
        <div class="row">
          <span>{{ $t("events.forWhom") }}</span>
          <span v-if="event?.is_korean">{{ $t("register.korean") }}</span>
          <span v-else>{{ $t("events.other") }}</span>
        </div>
        <div class="row">
          <span>{{ $t("events.regPeriodSite") }}</span>
          <span>
            {{ handleDate(event?.site_register_date_in) }}({{
              $t("week." + getWeek(event?.site_register_date_in))
            }})- {{ handleDate(event?.site_register_date_out) }}({{
              $t("week." + getWeek(event?.site_register_date_out))
            }})
          </span>
         
        </div>
        <div class="row">
          <span>{{ $t("events.examRegDeadline") }}</span>
          <span>
            {{ handleDate(event?.register_date_in) }}({{
              $t("week." + getWeek(event?.register_date_in))
            }}) - {{ handleDate(event?.register_date_out) }}({{
              $t("week." + getWeek(event?.register_date_out))
            }})
          </span>
        </div>
        <div class="row">
          <span>{{ $t("events.dueDate") }}</span>
          <span class="flex">
            <div class="alert">
              <svg
                width="1.19rem"
                height="1.25rem"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.791748 10.0003C0.791748 14.8098 4.6906 18.7087 9.50008 18.7087C14.3096 18.7087 18.2084 14.8098 18.2084 10.0003C18.2084 5.19085 14.3096 1.29199 9.50008 1.29199C4.6906 1.29199 0.791748 5.19085 0.791748 10.0003ZM16.6251 10.0003C16.6251 13.9354 13.4351 17.1253 9.50008 17.1253C5.56505 17.1253 2.37508 13.9354 2.37508 10.0003C2.37508 6.0653 5.56505 2.87533 9.50008 2.87533C13.4351 2.87533 16.6251 6.0653 16.6251 10.0003ZM10.2946 11.5823H11.0858V13.1657H7.91909V11.5823H8.71075V9.99902H7.91909V8.41568H10.2946V11.5823ZM10.2923 6.83235C10.2923 7.26958 9.93771 7.62402 9.50034 7.62402C9.06297 7.62402 8.70841 7.26958 8.70841 6.83235C8.70841 6.39512 9.06297 6.04068 9.50034 6.04068C9.93771 6.04068 10.2923 6.39512 10.2923 6.83235Z"
                  fill="#F43834"
                />
              </svg>
              <div class="alert-message">
                <p>
                  {{ $t("events.allertText") }}
                </p>
              </div>
            </div>
            {{ handleDate(event?.payment_date_in) }}({{
              $t("week." + getWeek(event?.payment_date_in))
            }})- {{ handleDate(event?.payment_date_out) }}({{
              $t("week." + getWeek(event?.payment_date_out))
            }})
          </span>
         
        </div>
        <div class="row">
          <span>{{ $t("events.dateAnnounceGrades") }}</span>
          <span>
            {{ handleDate(event?.announcement_date_in) }}({{
              $t("week." + getWeek(event?.announcement_date_in))
            }})
          </span>
         
        </div>
        <div class="row wrap">
          <span>{{ $t("events.status") }}</span>
          <Status :status="getStatus(event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Status from "@/components/Status";
import { defineProps } from "vue";
import { useFormatter } from "@/utils/formatter";

const { handleDate } = useFormatter();

defineProps({
  events: Array,
  examTypes: Array,
});

const getWeek = (initDate) => {
  let date = new Date(initDate);
  return date.getDay();
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const getStatus = (event) => {
  let currentDate = Date.parse(formatDate(new Date()));

  if (Date.parse(event.site_register_date_in) > currentDate) {
    return "planing";
  } else if (
    Date.parse(event.site_register_date_in) <= currentDate &&
    currentDate <= Date.parse(event.site_register_date_out)
  ) {
    return "registration_site";
  } else if (
    Date.parse(event.register_date_in) <= currentDate &&
    currentDate <= Date.parse(event.register_date_out)
  ) {
    return "registration";
  } else if (
    Date.parse(event.payment_date_in) <= currentDate &&
    currentDate <= Date.parse(event.payment_date_out)
  ) {
    return "payment";
  } else if (Date.parse(event.exam_date) == currentDate) {
    return "testing";
  } else if (
    Date.parse(event.announcement_date_in) <= currentDate &&
    currentDate <= Date.parse(event.announcement_date_out)
  ) {
    return "results";
  } else if(currentDate >= Date.parse(event.register_date_out) && currentDate <= Date.parse(event.payment_date_in)) {
    return 'site_register_complete';
  } else if (Date.parse(event.announcement_date_in) > currentDate) {
    return "processing";
  } else {
    return "completed";
  }
};
</script>

<style lang="scss" scoped>
.indicator {
  width: 0.8rem;
  height: 0.8rem;
  // background: var(--purple);
  border-radius: 0.25rem;
  margin-right: 0.63rem;
  display: inline-block;
}
.flex {
  display: flex;
  align-items: center;

  &-column {
    display: flex;
    flex-direction: column;
  }
}
.alert {
  position: relative;
  display: inline-block;
  margin-right: 0.3rem;

  &:hover {
    .alert-message {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-message {
    position: absolute;
    bottom: 1.7rem;
    left: 0;
    width: 15rem;
    border: 0.13rem solid var(--error-color);
    background: var(--white);
    padding: 0.75rem;
    border-radius: 0.63rem;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;

    p {
      font-weight: 400;
      font-size: 1.5em;
      line-height: 180%;
      margin-right: 0.3rem;
      display: inline-block;

      &:last-child {
        color: var(--error-color);
      }
    }

    box-shadow: 0rem 0.5rem 1.75rem -0.38rem rgba(24, 39, 75, 0.12),
      0rem 1.13rem 5.5rem -0.25rem rgba(24, 39, 75, 0.14);
  }
}

.divider {
  width: 100%;
  height: 0.06rem;
  background: var(--border-light);

  &-vertical {
    width: 0.06rem;
    min-height: 100%;
    background: var(--border-light);
  }
}
.wrap {
  flex-wrap: wrap;
  span {
    margin: 0.3125rem 0;
  }
}
.events-mobile {
  font-size: 16px;
  .item {
    &-title {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 160%;
      color: var(--primary-blue);
      margin-bottom: 0.44rem;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &-table {
      display: grid;
      border-radius: 0.5rem;
      overflow: hidden;
      grid-template-columns: 1fr 1fr;
      background: var(--white);

      .row {
        padding: 1rem;
        flex-wrap: wrap;
        display: flex;
        min-height: 3rem;

        border: 0.03rem solid var(--border-light);

        .flex-column {
          flex: 1;
        }

        span {
          flex: 1;
          min-width: 6.25rem;
          font-size: 0.8em;
          line-height: 160%;
          color: var(--neutrals);
          margin: 0.05rem 0;

          &:first-child {
            color: var(--dark-blue);
            opacity: 0.5;
            margin-right: 0.31rem;
            margin-bottom: 0.31rem;
          }
        }
      }
    }
  }
}
</style>
