<template>
  <div class="events">
    <div class="container">
      <div class="events-wrap">
        <div class="events-head">
          <h2 class="events-title">{{ $t("events.lastEvents") }}</h2>
          <div class="exam-types">
            <div
              class="events-flug flex"
              v-for="examType in examTypes"
              :key="examType?.id"
            >
              <span
                class="indicator"
                :style="{ background: examType?.color }"
              ></span>
              <span>{{ examType?.title }}</span>
            </div>
          </div>
        </div>
        <MobileRecentEvents
          :events="events"
          :examTypes="examTypes"
          class="mobile"
        />
        <div class="events-table desktop">
          <table class="table">
            <tr class="table-head">
              <th>
                <div class="head-text max-content">{{ $t("events.exam") }}</div>
              </th>
              <th>
                <span class="head-text">{{ $t("events.examDate") }}</span>
              </th>
              <th>
                <div class="head-text max-content">
                  {{ $t("events.forWhom") }}
                </div>
              </th>
              <th>
                <span class="head-text">{{ $t("events.regPeriodSite") }}</span>
              </th>
              <th>
                <span class="head-text">{{
                  $t("events.examRegDeadline")
                }}</span>
              </th>
              <th>
                <div class="head-text">
                  <div class="flex wrap">
                    <div
                      :class="{
                        'max-content': i18n.global.locale.value == 'ko',
                      }"
                    >
                      {{ $t("events.dueDate") }}
                    </div>
                    <div
                      :class="{
                        'max-content': i18n.global.locale.value == 'ko',
                      }"
                    >
                      {{ $t("events.dueDate2") }}
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <span class="head-text text-center">{{
                  $t("events.status")
                }}</span>
              </th>
              <th>
                <span class="head-text">{{
                  $t("events.dateAnnounceGrades")
                }}</span>
              </th>
            </tr>
            <tr
              class="table-item"
              v-for="(event, index) in events"
              :style="{
                background:
                  index % 2 == 0 ? 'var(--white)' : 'var(--light-gray)',
              }"
              :key="event"
            >
              <td>
                <template v-for="examType in examTypes" :key="examType?.id">
                  <div
                    class="indicator"
                    v-if="examType?.id == event?.exam_type"
                    :style="{ background: examType?.color }"
                  ></div>
                </template>
              </td>
              <td>
                <div class="item-text">
                  {{ $t("events.examNumKo") }}{{ event?.exam_numb
                  }}{{ $t("events.examNum") }}
                </div>
                <div class="item-text">
                  {{ handleDate(event?.exam_date) }}({{
                    $t("week." + getWeek(event?.exam_date))
                  }})
                </div>
              </td>
              <td>
                <div class="item-text" v-if="event?.is_korean">
                  {{ $t("events.korean") }}
                </div>
                <div class="item-text" v-else>{{ $t("events.other") }}</div>
              </td>
              <td>
                <div class="flex">
                  <div class="item-text">
                    {{ handleDate(event?.site_register_date_in) }}({{
                      $t("week." + getWeek(event?.site_register_date_in))
                    }})- {{ handleDate(event?.site_register_date_out) }}({{
                      $t("week." + getWeek(event?.site_register_date_out))
                    }})
                  </div>
                </div>
              </td>
              <td>
                <div class="flex">
                  <div class="item-text">
                    {{ handleDate(event?.register_date_in) }}({{
                      $t("week." + getWeek(event?.register_date_in))
                    }})- {{ handleDate(event?.register_date_out) }}({{
                      $t("week." + getWeek(event?.register_date_out))
                    }})
                  </div>
                </div>
              </td>
              <td>
                <div class="flex start">
                  <div class="alert">
                    <svg
                      width="1.19rem"
                      height="1.25rem"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.791748 10.0003C0.791748 14.8098 4.6906 18.7087 9.50008 18.7087C14.3096 18.7087 18.2084 14.8098 18.2084 10.0003C18.2084 5.19085 14.3096 1.29199 9.50008 1.29199C4.6906 1.29199 0.791748 5.19085 0.791748 10.0003ZM16.6251 10.0003C16.6251 13.9354 13.4351 17.1253 9.50008 17.1253C5.56505 17.1253 2.37508 13.9354 2.37508 10.0003C2.37508 6.0653 5.56505 2.87533 9.50008 2.87533C13.4351 2.87533 16.6251 6.0653 16.6251 10.0003ZM10.2946 11.5823H11.0858V13.1657H7.91909V11.5823H8.71075V9.99902H7.91909V8.41568H10.2946V11.5823ZM10.2923 6.83235C10.2923 7.26958 9.93771 7.62402 9.50034 7.62402C9.06297 7.62402 8.70841 7.26958 8.70841 6.83235C8.70841 6.39512 9.06297 6.04068 9.50034 6.04068C9.93771 6.04068 10.2923 6.39512 10.2923 6.83235Z"
                        fill="#F43834"
                      />
                    </svg>
                    <div class="alert-message">
                      <span> {{ $t("events.allertText") }}</span>
                    </div>
                  </div>

                  <div class="item-text">
                    {{ handleDate(event?.payment_date_in) }}({{
                      $t("week." + getWeek(event?.payment_date_in))
                    }})- {{ handleDate(event?.payment_date_out) }}({{
                      $t("week." + getWeek(event?.payment_date_out))
                    }})
                  </div>
                </div>
              </td>
              <td>
                <div class="item-text flex">
                  <Status :status="getStatus(event)" />
                </div>
              </td>
              <td>
                <div class="flex">
                  <div class="item-text">
                    {{ handleDate(event?.announcement_date_in) }}({{
                      $t("week." + getWeek(event?.announcement_date_in))
                    }})
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Status from "@/components/Status";
import MobileRecentEvents from "@/components/MobileRecentEvents";
import { useStore } from "vuex";
import { computed } from "vue";
import { useFormatter } from "@/utils/formatter";
import { useLanguage } from "@/utils/language";

const { i18n } = useLanguage();
const { handleDate } = useFormatter();

// <!-- - {{ handleDate(event?.announcement_date_out) }}({{
//                     $t("week." + getWeek(event?.announcement_date_out))
//                   }}) -->

const store = useStore();

const getWeek = (initDate) => {
  let date = new Date(initDate);

  return date.getDay();
};

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const getStatus = (event) => {
  let currentDate = Date.parse(formatDate(new Date()));
  if (Date.parse(event.site_register_date_in) > currentDate) {
    return "planing";
  } else if (
    Date.parse(event.site_register_date_in) <= currentDate &&
    currentDate <= Date.parse(event.site_register_date_out)
  ) {
    return "registration_site";
  } else if (
    Date.parse(event.register_date_in) <= currentDate &&
    currentDate <= Date.parse(event.register_date_out)
  ) {
    return "registration";
  } else if (
    Date.parse(event.payment_date_in) <= currentDate &&
    currentDate <= Date.parse(event.payment_date_out)
  ) {
    return "payment";
  } else if (Date.parse(event.exam_date) == currentDate) {
    return "testing";
  } else if (
    Date.parse(event.announcement_date_in) <= currentDate &&
    currentDate <= Date.parse(event.announcement_date_out)
  ) {
    return "results";
  } else if(currentDate >= Date.parse(event.register_date_out) && currentDate <= Date.parse(event.payment_date_in)) {
    return 'site_register_complete';
  } else if (Date.parse(event.exam_date) > currentDate) {
    return "processing";
  } else {
    return "completed";
  }
};

store.dispatch("appeal/getLastEvents");
store.dispatch("appeal/getExamTypes");

const events = computed(() => store.getters["appeal/lastEvents"]);
const examTypes = computed(() => store.getters["appeal/examTypes"]);
</script>

<style lang="scss" scoped>
table,
tr,
th,
td {
  border-collapse: collapse;
}

.exam-types {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.2rem;
  max-width: 60%;
  justify-content: flex-end;
}

.max-content {
  width: max-content;
}

.alert {
  position: relative;
  margin-right: 0.6rem;

  &:hover {
    z-index: 11;
    .alert-message {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-message {
    position: absolute;
    top: 1.4rem;
    left: 0;
    width: 24rem;
    border: 0.13rem solid var(--error-color);
    background: var(--white);
    padding: 0.75rem;
    border-radius: 0.63rem;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;

    span {
      font-weight: 400;
      font-size: 0.75rem;
      margin-right: 0.3rem;

      &:last-child {
        color: var(--error-color);
      }
    }

    box-shadow: 0rem 0.5rem 1.75rem -0.38rem rgba(24, 39, 75, 0.12),
      0rem 1.13rem 5.5rem -0.25rem rgba(24, 39, 75, 0.14);
  }
}

.text-center {
  text-align: center;
}

.mobile {
  display: none;
}

.events {
  width: 100%;
  background: var(--light-gray);
  padding: 5.5rem 0;

  .status {
    margin: 0 auto;
  }

  .flex {
    display: flex;
    align-items: center;

    &.wrap {
      flex-wrap: wrap;
    }

    &.inline {
      display: inline-flex;
    }

    &.start {
      align-items: flex-start;
    }
  }

  &-table {
    padding-bottom: 1.3125rem;
    th {
      vertical-align: top;
      text-align: left;
      padding: 0 0.625rem;
      &:first-child {
        padding-left: 2rem;
      }

      &:last-child {
        padding-right: 2rem;
      }

      &:nth-child(6) {
        padding: 0;
      }
      &:nth-child(7) {
        padding-left: 0.5rem;
      }
    }
    td {
      vertical-align: middle;
      padding: 1.375rem 0.625rem;

      &:first-child {
        padding-left: 2rem;
      }
      &:nth-child(6) {
        padding-right: 0.5rem;
      }
      &:nth-child(7) {
        padding-left: 0.5rem;
      }
      &:last-child {
        padding-right: 2rem;
      }
    }

    tr {
      &:first-child {
        border-bottom: 0.06rem solid var(--border-light);
        border-top: 0.06rem solid var(--border-light);
      }
    }
    .table {
      width: 100%;
      .head-text {
        font-weight: 500;
        font-size: 0.8rem;
        color: var(--dark-blue);
        opacity: 0.5;
        padding: 1.3rem 0;
        display: block;
      }

      &-item {
        padding: 0 2.125rem;
        .item {
          &-text {
            font-weight: 400;
            font-size: 0.875em;
            max-width: 6.875rem;
            color: var(--neutrals);
          }
        }
      }
    }
  }

  .indicator {
    width: 1.0625rem;
    height: 1.0625rem;
    // background: var(--purple);
    border-radius: 0.25rem;
    display: block;
  }

  &-wrap {
    border-radius: 1.125rem;
    padding: 1.875rem 0;
    background: var(--white);
    // overflow: hidden;
    border: 0.0625rem solid #ffffff;
  }

  &-title {
    font-weight: 700;
    font-size: 1.875em;
    color: var(--neutrals);
  }

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.125rem;
    padding-bottom: 1.5rem;
  }

  &-flug {
    span {
      font-weight: 400;
      font-size: 0.875em;
      margin-left: 0.5rem;
      line-height: 160%;
      color: var(--neutrals60);
    }
  }
}

@media screen and (max-width: 1024px) {
  .events {
    padding: 3rem 0;

    &-title {
      font-weight: 700;
      font-size: 1.5rem;
      color: var(--neutrals);
    }

    &-flug {
      span {
        font-size: 0.75rem;
      }
    }

    .indicator {
      width: 0.75rem;
      height: 0.75rem;
    }

    &-table {
      .table {
        .head-text {
          font-size: 0.75em;
        }

        th {
          padding: 0 0.31rem;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }
        td {
          padding: 1.375rem 0.31rem;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }

        &-item {
          padding: 0 2.13rem;
          .item {
            &-text {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .alert {
    &-message {
      width: 19rem;
    }
  }
  .events {
    font-size: 16px;
    padding: 1.88rem 0;

    &-title {
      font-weight: 700;
      font-size: 1.13rem;
      color: var(--neutrals);
    }

    &-flug {
      span {
        font-size: 0.63rem;
      }
    }

    &-table {
      .table {
        .head-text {
          font-size: 0.5em;
        }

        th {
          padding: 0 0.31rem;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }
        td {
          padding: 1.375rem 0.31rem;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }

        &-item {
          padding: 0 2.13rem;
          .item {
            &-text {
              font-size: 0.5em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .exam-types {
    max-width: 100%;
    justify-content: flex-start;
  }

  .events {
    &-title {
      margin-bottom: 1rem;
      font-size: 1.4em;
    }

    &-wrap {
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 0;
    }

    &-flug {
      span {
        font-size: 01em;

        &:first-child {
          margin: 0;
        }
      }
    }

    &-head {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      margin-bottom: 1.2rem;
    }
  }
}
</style>
